<template>
    <Teleport to="body">
        <div class="trust-modal">
            <div class="trust-modal__overlay" @click="() => emit('close')" />
            <div class="trust-modal__wrapper">
                <button class="trust-modal__close" @click="() => emit('close')">
                    <NuxtIcon name="ri:close-line" />
                </button>
                <div class="trust-modal__header">
                    <h5 class="trust-modal__title">{{ t('trustModalData.title') }}</h5>
                </div>
                <div class="trust-modal__content">
                    <p v-for="(text, index) in $tm('trustModalData.content')" :key="index">{{ rt(text as any) }}</p>
                </div>
                <div class="trust-modal__logos">
                    <img src="~/assets/images/securecards.png" loading="lazy" />
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script setup lang="ts">
const { t, rt, locale } = useI18n();

// For index page reassign locale to 'en'
const { fullPath } = useRoute();
if (fullPath === '/') locale.value = 'en';
const emit = defineEmits<{ (e: 'close'): void }>();
</script>

<style lang="postcss" scoped>
.trust-modal {
    @apply fixed z-[100] w-full h-full left-0 top-0 overflow-y-auto;
    &__overlay {
        @apply fixed z-0 w-full h-full left-0 top-0 bg-[#969097] bg-opacity-70;
    }
    &__wrapper {
        @apply relative z-10 bg-white pb-2.5 my-8 tablet:my-20 mx-1.5 sm:mx-auto min-w-[306px] max-w-[700px];
        border: 2px solid #4b1862;
        @apply rounded-md overflow-hidden;
    }
    &__header {
        @apply bg-primary pt-2.5 pb-3.5 px-7 relative;
    }
    &__title {
        @apply text-lg tablet:text-[22px] text-white font-bold leading-5 tablet:leading-7 pl-20 pr-16 relative;
        &:before {
            content: '';
            @apply top-1 left-0 absolute w-[45px] h-[52px];
        }
    }
    &__content {
        @apply pt-3.5 pb-8 px-7 text-primary text-base;
        p + p {
            @apply mt-5;
        }
    }
    &__logos {
        @apply px-7;
    }
    &__close {
        @apply z-10 absolute border-none top-2 right-2 overflow-hidden;

        .iconify {
            @apply w-[31px] h-[31px] inline-block bg-white;
        }
    }
}
</style>
